import React from 'react'

const InfoCard = ({ image, title, copy }) => {
  return (
    <div className="info-card">
      <img src={image} alt={title} className="copy-img info-card-img" />
      <h5 className="info-card-header">{title}</h5>
      <p className="info-card-copy">
        {copy}
      </p>
    </div>
  )
}

export default InfoCard
