import Nav from './components/Nav/Nav';
import { Grid } from '@material-ui/core';
import CopyGrid from './components/Copy/CopyGrid';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Grid container>
        <Grid item xl={3} lg={1} sm={false} />
        <Grid item container xl={6} lg={10} className="main-container">
          <Grid item xs={12}>
            <Nav />
          </Grid>
          <Grid item container xs={12}>
            <CopyGrid />
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
        <Grid item xl={3} lg={1} sm={false} />
      </Grid>
    </div>
  );
}

export default App;
