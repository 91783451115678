import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  brandText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: 32
    }
  }
}));

const Brand = () => {
  const classes = useStyles();
  return (
    <a href="https://app.boxswap.io" className="brand-link">
      <div className="brand">
        <img
          src="./img/main/logo_white.svg"
          className={classnames('logo', classes.logo)}
          alt="logo"
        />
        <span className={classnames('brand-text', classes.brandText)}>
          boxswap
        </span>
      </div>
    </a>
  );
};

export default Brand;
