import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import InfoCard from './InfoCard/InfoCard';
import { copy } from './consts';
import MainInfo from './MainInfo/MainInfo';

const CopyGrid = () => {
  return (
    <Fragment>
      <Grid item container xs={12}>
        <div className="top-row">
          <MainInfo />
          <img
            src="./img/main/boxes_1.svg"
            alt="boxes"
            className="copy-img main-copy-img"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <h2 className="how-boxswap-works">HOW BOXSWAP WORKS</h2>
      </Grid>
      <Grid item container xs={12} className="info-card-holder">
        <Grid item md={4} sm={12}>
          <InfoCard
            image={'./img/main/pool_1.svg'}
            title={'POOL'}
            copy={copy.pool}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <InfoCard
            image={'./img/main/swap_1.svg'}
            title={'SWAP'}
            copy={copy.swap}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <InfoCard
            image={'./img/main/manage_1.svg'}
            title={'MANAGE'}
            copy={copy.manage}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CopyGrid;
