import React from 'react'
import Brand from './Brand'
import SignInButton from './SignInButton'

const Nav = () => {
  return (
    <nav className="nav">
      <Brand />
      <SignInButton />
    </nav>
  )
}

export default Nav