import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}));

const SignInButton = () => {
  const classes = useStyles();
  return (
    <a
      href="https://app.boxswap.io"
      className={classnames('button', 'button-outlined', classes.button)}
    >
      ENTER APP
    </a>
  );
};

export default SignInButton;
