import React from 'react'

const SocialButton = ({ url, children }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="social-link">
      {children}
    </a>
  )
}

export default SocialButton
