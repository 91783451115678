export const copy = {
	mainHeader: 'Instant NFT selling, buying, and staking',
	main:
		'Boxswap aligns the popular AMM incentive model for NFTs. Staking NFTs creates a community of liquidity providers, allowing instant buying and selling of NFTs at anti-fraudulent prices.',
	pool:
		'Liquidity providers stake equal reserves of two assets. When users swap, LP’s earn a cut of the pool’s fees proportional to their stake.',
	swap:
		'After checking the terms of your swap, you can instantly sell from NFT to Tokens or vice versa. ',
	manage:
		'Users take advantage of our NFT-friendly wallet to manage assets. It’s easy to swap NFTs to their counter-part FT versions.'
};
