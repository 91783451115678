import React from 'react';
import SocialButton from './SocialButton';
import { ReactComponent as DiscordIcon } from '../../socials/discord.svg';
import { ReactComponent as TwitterIcon } from '../../socials/twitter.svg';
import { ReactComponent as MediumIcon } from '../../socials/medium.svg';
//import { ReactComponent as YoutubeIcon } from '../../socials/youtube.svg'
import { ReactComponent as MailIcon } from '../../socials/mail.svg';
// <SocialButton url="https://www.youtube.com/channel/UCG9UOsvZIZQuIpsvYkUJC9A">
//   <YoutubeIcon className="social-icon" />
// </SocialButton>
const Footer = () => {
  return (
    <div className="footer">
      <SocialButton url="https://discord.gg/jbtj5Q6FKp">
        <DiscordIcon className="social-icon" />
      </SocialButton>
      <SocialButton url="https://twitter.com/boxswapio">
        <TwitterIcon className="social-icon" />
      </SocialButton>
      <SocialButton url="https://medium.com/boxswap">
        <MediumIcon className="social-icon" />
      </SocialButton>

      <SocialButton url="mailto:info@boxswap.io">
        <MailIcon className="social-icon" />
      </SocialButton>
    </div>
  );
};

export default Footer;
