import React from 'react';
import { copy } from '../consts';
import { Grid } from '@material-ui/core';
import MainButtons from './MainButtons';

const MainInfo = () => {
  return (
    <div className="main-info">
      <Grid container>
        <Grid item xs={12}>
          <h1 className="main-info-header">{copy.mainHeader.toUpperCase()}</h1>
        </Grid>
        <Grid item xs={12}>
          <p className="main-info-copy">{copy.main}</p>
        </Grid>
        <MainButtons />
      </Grid>
    </div>
  );
};

export default MainInfo;
