import React from 'react';

const MainButtons = () => {
	return (
		<div>
			<a
				href="https://app.boxswap.io/#/swap"
				className="button button-primary button-large main-button mr-15"
			>
				SWAP
			</a>
			<a
				href="hhttps://app.boxswap.io/#/pool"
				className="button button-secondary button-large main-button"
			>
				STAKE
			</a>
		</div>
	);
};

export default MainButtons;
